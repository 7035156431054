import { ApiCall } from "../apiBase";

export async function sendMessage(newMessage) {
  let response = null;
  const { firstName, lastName, email, message } = newMessage;
  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            mutation Mutation($sendMessage: Message!) {
                sendMessage(message: $sendMessage) {
                    status
                    message
                }
            }
            `,
      variables: {
        sendMessage: {
          firstName,
          lastName,
          email,
          message
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.sendMessage) {
    response = result.data.data.sendMessage;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return response;
}

<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import * as contactService from "@/api/services/contact.service";

Vue.use(VueFormulate, {
  classes: {
    element: ""
  }
});

export default {
  name: "Contact",
  components: {
    CommonLayout
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      alertVariant: null,
      alertMessage: null,
      showAlert: false
    };
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  methods: {
    openWhatsapp() {
      window.open("https://wa.me/message/CW6P5M5QLQ3JN1", "_blank");
    },
    async sendMessage() {
      try {
        const message = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message
        };
        let response = await contactService.sendMessage(message);
        if (response.status === "SUCCESS") {
          this.showAlert = true;
          this.alertVariant = "success";
          this.alertMessage = "Thanks for contacting us - our team will be in touch shortly";
          this.resetForm();
        }
      } catch (error) {
        this.showAlert = true;
        this.alertVariant = "danger";
        this.alertMessage = "Oops! Something went wrong.";
      }
    },
    resetForm() {
      this.$formulate.reset("message");
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="contact-content">
        <div class="heading-container animate__animated animate__fadeInDown">
          <div class="contact-column">
            <h1 class="page-title title-responsive">{{ $t("contact.contactTitle") }}</h1>
          </div>
        </div>
        <div class="row animate__animated animate__fadeIn animate__slow">
          <div class="contact-column">
            <p class="paragraph">
              {{ $t("contact.paragraph1") }}
            </p>
            <div class="contact-container-top-contacts-page">
              <div class="contact-container-top-contacts-wrapper tel-padding">
                <em class="fas fa-phone-alt contact-container-contact-icon"></em>
                <div>
                  <div class="contact-container-top-contacts-hd">
                    {{ $t("footer.telephone") }}
                  </div>
                  <div class="contact-container-top-contacts-txt">
                    <a id="mail-link" :href="`tel:+94 77 1234 567`">
                      (+94) 77 1234 567
                    </a>
                  </div>
                </div>
              </div>
              <div class="contact-container-top-contacts-wrapper mail-padding">
                <em class="fas fa-envelope contact-container-contact-icon"></em>
                <div>
                  <div class="contact-container-top-contacts-hd">
                    {{ $t("footer.email") }}
                  </div>
                  <div class="contact-container-top-contacts-txt">
                    <a id="mail-link" :href="`mailto:info@explorethelocal.com`"
                      >info@explorethelocal.com</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <button class="form-button whatsapp-btn" @click="openWhatsapp()">
              <div class="btn-content">
                <em class="icon fab fa-whatsapp" />{{ $t("contact.whatsapp-btn") }}
              </div>
            </button>
          </div>
          <!--div column used for spacing-->
          <div class="space-column" />
          <div class="contact-column">
            <h5 class="form-heading">{{ $t("contact.form-title") }}</h5>
            <b-alert :variant="alertVariant" :show="showAlert" class="message-alert">
              {{ alertMessage }}
            </b-alert>
            <FormulateForm name="message" @submit="sendMessage()">
              <div class="form-column">
                <FormulateInput
                  type="text"
                  name="First Name"
                  ref="firstNameField"
                  placeholder="First Name"
                  validation="required"
                  v-model="firstName"
                />
                <FormulateInput
                  type="text"
                  name="Last Name"
                  ref="lastNameField"
                  placeholder="Last Name"
                  validation="required"
                  v-model="lastName"
                />
              </div>
              <FormulateInput
                type="text"
                name="Email"
                ref="emailField"
                placeholder="Email"
                validation="required|email"
                class="form-input"
                v-model="email"
              />
              <FormulateInput
                type="textarea"
                v-model="message"
                ref="messageField"
                placeholder="How can we assist?"
                validation="required|max:500,length"
                rows="5"
                validation-name="Message"
                class="form-input"
              />
              <button class="form-button message-button" type="submit">
                <div class="btn-content">
                  {{ $t("contact.message-btn") }}
                </div>
              </button>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "@/styles/views/_contact.scss";
</style>
